import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-red ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_textarea = _resolveComponent("input-textarea")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_centered_modal, {
        key: 0,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
        title: 
            _ctx.toEditData && _ctx.toEditData.id
                ? _ctx.$t('settings.changeChangeTypes')
                : _ctx.$t('settings.addChangeTypes')
        ,
        cancelButtonText: _ctx.$t('cancel'),
        mainContainerId: 'settings_container'
      }, {
        actionButton: _withCtx(() => [
          _createVNode(_component_custom_button, {
            id: 'save-button',
            class: "max-w-full",
            isDisabled: !_ctx.isAllFilled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createEmailType()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.toEditData && _ctx.toEditData.id ? _ctx.$t("save") : _ctx.$t("apply")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled"])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_input_default, {
            id: 'type-name',
            name: 'type-name',
            modelValue: _ctx.mailType.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mailType.name) = $event)),
            type: 'text',
            label: _ctx.$t('settings.name_of_type'),
            class: "w-full mb-4"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_input_textarea, {
            id: 'textarea-input-changingText',
            name: "textarea-input-changingText",
            label: _ctx.$t('settings.changingText'),
            rows: 3,
            modelValue: _ctx.mailType.email_content,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mailType.email_content) = $event))
          }, null, 8, ["label", "modelValue"]),
          (_ctx.toEditData.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_custom_button, {
                  id: 'delete-button',
                  class: "max-w-full",
                  isPlainButton: true,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('delete')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_icon_trash, { color: 'red' }),
                      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("delete_changeType")), 1)
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "cancelButtonText"]))
    : _createCommentVNode("", true)
}