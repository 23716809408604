import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "search",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1771",
      "data-name": "Rechteck 1771",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "search-2",
      "data-name": "search",
      d: "M494.859,108.468l-4.27-4.269c-.02-.02-.046-.027-.067-.045a6.907,6.907,0,1,0-1.368,1.368c.018.021.025.047.045.067l4.269,4.27a.984.984,0,1,0,1.391-1.391Zm-9.831-3.525a4.915,4.915,0,1,1,4.915-4.915A4.921,4.921,0,0,1,485.028,104.943Z",
      transform: "translate(-475.146 -90.146)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}