import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "edit1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1801",
      "data-name": "Rechteck 1801",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline2",
      d: "M860,112H846a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h14a1,1,0,0,0,0-2Z",
      transform: "translate(-841 -92)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M856.106,96.195l1.758,1.021-6,10.456a.584.584,0,0,1-.135.165l-1.45.554-.246-1.525a.5.5,0,0,1,.072-.214l6-10.457M856.032,94a1.932,1.932,0,0,0-1.6,1.031l-6.084,10.6a2.406,2.406,0,0,0-.321,1.546l.548,3.4a.505.505,0,0,0,.5.426.515.515,0,0,0,.179-.033l3.2-1.221a2.387,2.387,0,0,0,1.173-1.054l6.084-10.6a1.737,1.737,0,0,0-.385-2.391l-2.637-1.531a1.281,1.281,0,0,0-.65-.172Z",
      transform: "translate(-841 -92)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}