import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mail_content = _resolveComponent("mail-content")!
  const _component_change_types = _resolveComponent("change-types")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name == 'SettingsEmail')
      ? (_openBlock(), _createBlock(_component_mail_content, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$route.name == 'SettingsEmailTypes')
      ? (_openBlock(), _createBlock(_component_change_types, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}