import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between w-full" }
const _hoisted_2 = {
  key: 0,
  class: "my-1.5 w-full"
}
const _hoisted_3 = { class: "pt-8 pb-4 px-6 max-h-full overflow-auto scrollbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_modal_container = _resolveComponent("modal-container")!

  return (_openBlock(), _createBlock(_component_modal_container, {
    transitionDuration: _ctx.transitionDuration,
    modalBackdropStyle: _ctx.modalStyle,
    mainContainerId: _ctx.mainContainerId,
    isCentered: false,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    ref: "modalContainerElement"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["pointer-events-auto w-full max-h-full min-h-93 max-w-216 flex flex-col", `bg-${_ctx.finalCenteredModalStyle.backgroundColor} shadow-${_ctx.finalCenteredModalStyle.shadow} rounded-${_ctx.finalCenteredModalStyle.roundedSize}`])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["relative w-full py-1.5 px-6 min-h-13 flex flex-wrap items-center justify-center", `bg-${_ctx.finalCenteredModalStyle.headerBackgroundColor} rounded-t-${_ctx.finalCenteredModalStyle.roundedSize} border-b border-${_ctx.finalCenteredModalStyle.spacerColor}`])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "overflow-hidden",
              style: _normalizeStyle(`width:${
                            (100 - _ctx.finalCenteredModalStyle.titleWidth) / 2
                        }%`)
            }, [
              (_ctx.$slots.prependButton == null)
                ? (_openBlock(), _createBlock(_component_custom_button, {
                    key: 0,
                    id: 'back-button',
                    class: "max-w-full",
                    onClick: _ctx.closeModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "prependButton")
            ], 4),
            _createElementVNode("p", {
              class: _normalizeClass(["text-center truncate", `text-${_ctx.finalCenteredModalStyle.titleColor} 
                        font-${_ctx.finalCenteredModalStyle.titleFontWeight} 
                        text-${_ctx.finalCenteredModalStyle.titleSize}`]),
              style: _normalizeStyle(`width:${_ctx.finalCenteredModalStyle.titleWidth}%`)
            }, _toDisplayString(_ctx.title), 7),
            _createElementVNode("div", {
              class: "overflow-hidden flex flex-row justify-end items-center",
              style: _normalizeStyle(`width:${
                            (100 - _ctx.finalCenteredModalStyle.titleWidth) / 2
                        }%`)
            }, [
              _renderSlot(_ctx.$slots, "actionButton")
            ], 4)
          ]),
          (_ctx.$slots.prependHeader != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "prependHeader")
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content")
        ])
      ], 2)
    ]),
    _: 3
  }, 8, ["transitionDuration", "modalBackdropStyle", "mainContainerId"]))
}