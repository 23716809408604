
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { computed, ComputedRef, defineComponent, ref } from "vue";
import ModalContainer from "./ModalContainer.vue";
import { centeredModalDefaultStyle } from "./modal_default_style";
import { ModalStyleInterface } from "./modal_interfaces";

/**
 * slots: prependButton, actionButton, prependHeader, content
 */
export default defineComponent({
    components: { ModalContainer, CustomButton },
    name: "CenteredModal",
    emits: ["close"],
    props: {
        /**
         * The text of the cancelButton
         */
        cancelButtonText: {
            type: String,
            default: "Cancel",
        },
        /**
         * The text of the title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * style of the modal
         */
        modalStyle: {
            type: Object as () => ModalStyleInterface,
            default: {} as ModalStyleInterface,
        },
        /**
         * Duration in Milliseconds for transition
         */
        transitionDuration: {
            type: Number,
            default: 150,
        },
        /**
         * Main Container id to resize depending on the container
         */
        mainContainerId: {
            type: String,
            default: "main-container",
        },
    },
    setup(props) {
        const finalCenteredModalStyle: ComputedRef<ModalStyleInterface> =
            computed(() => {
                return {
                    ...centeredModalDefaultStyle,
                    ...getSpaceConfigStyle()?.centeredModal,
                    ...props.modalStyle,
                };
            });

        const modalContainerElement =
            ref<InstanceType<typeof ModalContainer>>();
        /**
         * this close modal function is triggering the closeModal function from
         * the modal container to create a transition
         *
         * @returns void
         */
        function closeModal(): void {
            modalContainerElement.value?.closeModal();
        }
        return {
            finalCenteredModalStyle,
            modalContainerElement,
            closeModal,
        };
    },
});
