import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 pl-8 w-full h-full overflow-y-auto" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "text-grey-darker mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_textarea = _resolveComponent("input-textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t("settings.mailContent")), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("settings.change_email_description")), 1),
    _createVNode(_component_input_default, {
      id: 'inputRegardID',
      name: 'inputRegard',
      modelValue: _ctx.emailContent.subject,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailContent.subject) = $event)),
      type: 'text',
      label: _ctx.$t('settings.mail.regard'),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateMailContent()))
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_input_textarea, {
      id: 'textInputAreaHeader',
      name: "textarea-input-header",
      modelValue: _ctx.emailContent.header,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailContent.header) = $event)),
      label: _ctx.$t('settings.mail.header'),
      rows: 3,
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateMailContent()))
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_input_textarea, {
      id: 'textInputAreaFooter',
      name: "textarea-input-footer",
      modelValue: _ctx.emailContent.footer,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.emailContent.footer) = $event)),
      label: _ctx.$t('settings.mail.footer'),
      rows: 3,
      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateMailContent()))
    }, null, 8, ["modelValue", "label"])
  ]))
}