
import { defineComponent } from "vue";

import IconEdit from "@/components/icons/IconEdit1.vue";
import { useNavigatorOptions } from "@/components/inputs/navigator_options";
import { EmailTypeInterface } from "@/store/settings/interfaces";

export default defineComponent({
    name: "DataContainer",
    components: { IconEdit },
    props: {
        item: {
            type: [Object as () => EmailTypeInterface],
            required: true,
        },
    },
    emits: ["delete", "edit"],
    setup() {
        const { isMobileDevice } = useNavigatorOptions({
            disableMobileStyle: false,
        });
        return { isMobileDevice };
    },
});
