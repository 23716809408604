import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-9 bg-white-dark mb-1 rounded-md transition-colors hover:bg-grey-lightest flex justify-between group" }
const _hoisted_2 = { class: "ml-5 flex items-center" }
const _hoisted_3 = { class: "flex items-center mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_edit = _resolveComponent("icon-edit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass(
                    _ctx.item.name
                        ? 'text-sm md:w-110 w-28 truncate overflow-hidden text-left'
                        : 'hidden'
                )
      }, _toDisplayString(_ctx.item.name), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "items-center justify-center h-7 w-7 rounded-md hover:bg-grey-light cursor-pointer flex",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
      }, [
        _createVNode(_component_icon_edit, { color: 'primary' })
      ])
    ])
  ]))
}