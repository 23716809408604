import ApiService from "@/common/api_service";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { MailStoreStateInterface } from "../interfaces";
import { EmailContentInterface, EmailTypeInterface } from "./interfaces";

export const useMailStore = defineStore({
    id: "mailStore",
    state: (): MailStoreStateInterface => ({
        emailContent: {
            subject: "",
            header: "",
            footer: "",
        },
        emailTypes: [],
    }),
    getters: {},
    actions: {
        /**
         * UPDATE
         */
        async updateMailType(
            mail: EmailTypeInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.put({
                resource: "norm-email-change-types",
                slug: mail.id,
                params: mail,
            });
            if (response?.status == 200) {
                const mailIndex = this.emailTypes.findIndex(
                    (item) => item.id == mail.id
                );
                this.emailTypes[mailIndex] = response?.data.data;
            }
            return response;
        },

        async updateEmailContent(
            content: EmailContentInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.put({
                resource: "norm-email-content",
                params: content,
            });
            return response;
        },

        /**
         * CREATE
         */
        async createMailType(
            mail: EmailTypeInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.post({
                resource: "norm-email-change-types",
                params: mail,
            });
            if (response?.status == 201) {
                this.emailTypes.push(response?.data.data);
            }
            return response;
        },

        /**
         * DELETE
         */
        async deleteMailType(
            mail: EmailTypeInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.delete({
                resource: "norm-email-change-types/" + mail.id,
            });

            if (response?.status == 200) {
                const mailIndex = this.emailTypes.findIndex(
                    (item) => item.id == mail.id
                );
                this.emailTypes.splice(mailIndex, 1);
            }
            return response;
        },

        /**
         * FETCH
         */
        async fetchMailTypes(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "norm-email-change-types",
            });
            this.emailTypes = response?.data.data;
            return response;
        },

        async fetchMailContent(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "norm-email-content",
            });
            this.emailContent = response?.data.data;
            return response;
        },
    },
});
