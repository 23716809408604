
import { computed, ComputedRef, defineComponent, ref } from "vue";
import IconSearch from "../../icons/IconSearch.vue";
import IconClose from "../../icons/IconClose.vue";
import { InputSearchStyleInterface } from "../input_interfaces";
import { inputDefaultStyle } from "../input_default_style";
import { metaHandlerDefaultStyle } from "../metaHandler/meta_handler_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";

export default defineComponent({
    name: "InputSearch",
    components: { IconSearch, IconClose },
    props: {
        /**
         * id is necessary to uniquely identify this input
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * specifies the name of the HTML Input element
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * the displayed value of the input
         */
        modelValue: {
            required: true,
        },
        /**
         *adds an overlay if it is disabled
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * The placeholder shonw in the input should be like "Search"
         */
        placeholder: {
            type: String,
            required: false,
        },

        /**
         * Configuration Object for Input Style with
         * following attributes:
         * {
         *      ! backgroundColor?: string;
         *      ! backgroundFocusColor?: string;
         *      ! backgroundHoverColor?: string;
         *      ! inputTextColor?: string;
         * }
         */
        inputStyle: {
            type: Object as () => InputSearchStyleInterface,
            default: {} as InputSearchStyleInterface,
        },
    },
    emits: ["change", "update:modelValue", "enter", "keydown"],
    setup(props, ctx) {
        const finalInputSearchStyle: ComputedRef<InputSearchStyleInterface> =
            computed(() => {
                return {
                    ...inputDefaultStyle,
                    ...metaHandlerDefaultStyle,
                    ...getSpaceConfigStyle()?.metaHandler,
                    ...getSpaceConfigStyle()?.inputSearch,
                    ...props.inputStyle,
                };
            });
        const inputElement = ref<HTMLInputElement>();
        /**
         * this function emits enter if the Enter key is getting pressed
         *
         * if the input type is 'number' and there is a key up or key down event
         * the number is getting increased or decreased
         */
        function handleKeyDownEvent(e: KeyboardEvent): void {
            ctx.emit("keydown", e);
            if (e.key == "Enter") {
                ctx.emit("enter");
                return;
            }
        }
        function handleChangeEvent(e: Event): void {
            let target = e.target as HTMLInputElement;
            ctx.emit("change", target.value);
        }
        function handleInputEvent(e: Event): void {
            let target = e.target as HTMLInputElement;
            ctx.emit("update:modelValue", target.value);
        }

        return {
            inputElement,
            handleChangeEvent,
            handleInputEvent,
            handleKeyDownEvent,
            finalInputSearchStyle,
        };
    },
});
