import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["placeholder", "id", "name", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_search = _resolveComponent("icon-search")!
  const _component_icon_close = _resolveComponent("icon-close")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref: "inputElement",
      class: _normalizeClass(["w-full truncate outline-none text-md transition-colors rounded-md py-2 pl-10", [
                `bg-${_ctx.finalInputSearchStyle.backgroundColor} text-${_ctx.finalInputSearchStyle.inputTextColor}`,
                _ctx.isDisabled
                    ? `bg-opacity-70 cursor-default text-opacity-70`
                    : `hover:bg-${_ctx.finalInputSearchStyle.backgroundHoverColor} focus:bg-${_ctx.finalInputSearchStyle.backgroundFocusColor}`,
            ]]),
      placeholder: _ctx.placeholder,
      id: _ctx.id,
      name: _ctx.name,
      value: _ctx.modelValue,
      disabled: _ctx.isDisabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInputEvent && _ctx.handleInputEvent(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeEvent && _ctx.handleChangeEvent(...args))),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDownEvent && _ctx.handleKeyDownEvent(...args)))
    }, null, 42, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-3 top-0 h-full w-6 flex items-center justify-center", _ctx.isDisabled ? ' cursor-default ' : 'cursor-text']),
      onClick: _cache[3] || (_cache[3] = 
                () => {
                    _ctx.$refs.inputElement.focus();
                }
            )
    }, [
      _renderSlot(_ctx.$slots, "icon"),
      (!_ctx.$slots.icon)
        ? (_openBlock(), _createBlock(_component_icon_search, { key: 0 }))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.modelValue != '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute right-3 top-0 h-full w-6 flex items-center justify-center cursor-pointer",
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('update:modelValue', '')), ["stop","prevent"]))
        }, [
          _renderSlot(_ctx.$slots, "closeIcon"),
          (!_ctx.$slots.closeIcon)
            ? (_openBlock(), _createBlock(_component_icon_close, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}