
import { defineComponent } from "vue";
import MailContent from "./views/Content.vue";
import ChangeTypes from "./views/ChangeTypes.vue";

export default defineComponent({
    name: "settingsEmail",
    components: { MailContent, ChangeTypes },
    setup() {
        return {};
    },
});
