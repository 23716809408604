
import { computed, ComputedRef, defineComponent, onMounted, ref } from "vue";
import { InputStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import MetaHandler from "../metaHandler/MetaHandler.vue";
import { inputDefaultStyle } from "../input_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { metaHandlerDefaultStyle } from "../metaHandler/meta_handler_default_style";

export default defineComponent({
  name: "InputTextarea",
  components: { MetaHandler },
  emits: ["change", "update:modelValue", "enter", "keydown"],
  props: {
    /**
     * id is necessary to uniquely identify this input
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * specifies the name of the HTML Input element
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * The placeholder shown in the input
     */
    placeholder: {
      type: String,
      required: false,
    },
    /**
     * displays ' *' behind the input label
     */
    isRequired: {
      type: Boolean,
      default: false,
    },
    /**
     *adds an overlay if it is disabled
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * enables or disables the option to resize on the Y axis
     */
    isResizable: {
      type: Boolean,
      default: true,
    },
    /**
     * the label of the input, if it is undefined the input is getting centered
     */
    label: {
      type: String,
      required: false,
    },
    /**
     * the displayed value of the input
     */
    modelValue: {
      required: true,
    },
    /**
     * number of available rows for the area
     */
    rows: {
      type: [String, Number],
      default: "1",
    },

    /**
     * if this is set the input saves space for the bottom meta container and the message can get displayed
     */
    hasMeta: {
      type: Boolean,
      default: true,
    },

    /**
     * Configuration Object for Meta Messages with
     * following attributes:
     * {
     *      ! errorMessage?: string;
     *      ! infoMessage?: string;
     *      ! saveMessage?: string;
     * }
     */
    inputMeta: {
      type: Object as () => InputMetaInterface,
      default: {} as InputMetaInterface,
    },

    /**
     * Configuration Object for Input Style with
     * following attributes:
     * {
     *      ! labelColor?: string;
     *      ! backgroundColor?: string;
     *      ! backgroundFocusColor?: string;
     *      ! backgroundHoverColor?: string;
     *      ! inputTextColor?: string;
     *      ! errorColor?: string;
     *      ! saveColor?: string;
     *      ! infoColor?: string;
     * }
     */
    inputStyle: {
      type: Object as () => InputStyleInterface,
      default: {} as InputStyleInterface,
    },
    /**
     * tabindex for the input
     */
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const finalTextareaStyle: ComputedRef<InputStyleInterface> = computed(
      () => {
        return {
          ...inputDefaultStyle,
          ...metaHandlerDefaultStyle,
          ...getSpaceConfigStyle()?.metaHandler,
          ...getSpaceConfigStyle()?.inputTextarea,
          ...props.inputStyle,
        };
      }
    );
    const textareaElement = ref<HTMLElement>();

    /**
     * function to call when input is given to the textarea
     * resizes to the needed height
     */
    function resizeTextarea() {
      if (textareaElement.value != null && props.isResizable) {
        textareaElement.value.style.height = "auto";
        textareaElement.value.style.height =
          textareaElement.value.scrollHeight + "px";
      }
    }

    /**
     * Import computed styles from InputStyleMixin
     */

    function handleChangeEvent(e: Event): void {
      if (props.isDisabled) return;
      const target = e.target as HTMLInputElement;

      ctx.emit("change", target.value);
    }
    function handleInputEvent(e: Event): void {
      if (props.isDisabled) return;
      const target = e.target as HTMLInputElement;

      ctx.emit("update:modelValue", target.value);
    }

    onMounted(() => {
      resizeTextarea();
    });

    return {
      textareaElement,
      resizeTextarea,
      handleChangeEvent,
      handleInputEvent,
      finalTextareaStyle,
    };
  },
});
