import { computed, ComputedRef } from "vue";

export function useNavigatorOptions(props: { disableMobileStyle?: boolean }): {
    isMobileDevice: ComputedRef<boolean>;
} {
    /**
     * return true if you are browsing on a mobile device
     */
    const isMobileDevice = computed(() => {
        if (props.disableMobileStyle) {
            return false;
        }
        const ua = navigator.userAgent;
        // ! if it is a tablet
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return true;
        }
        // ! if it is a mobile device
        else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return true;
        }

        return false;
    });

    return { isMobileDevice };
}
