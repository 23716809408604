import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "plus",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1773",
      "data-name": "Rechteck 1773",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "plus-2",
      "data-name": "plus",
      d: "M406,93a1,1,0,0,0-1,1v6h-6a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0v-6h6a1,1,0,0,0,0-2h-6V94a1,1,0,0,0-1-1Z",
      transform: "translate(-394 -89)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}