
import { computed, defineComponent, ref, watch } from "vue";
import CenteredModal from "@/components/layout/modals/CenteredModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputTextarea from "@/components/inputs/inputTextarea/InputTextarea.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import { EmailTypeInterface } from "@/store/settings/interfaces";
import { cloneData } from "@/common/cloneData";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { storeToRefs } from "pinia";
import { useMailStore } from "@/store/settings/mailStore";

export default defineComponent({
    name: "addTypeModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        InputTextarea,
        IconTrash,
    },
    props: {
        toEditData: {
            type: Object as () => EmailTypeInterface,
            required: true,
        },
        open: {
            type: Boolean,
            required: true,
        },
        type: {
            type: String,
            required: false,
        },
    },
    emits: ["close", "saveImage", "delete"],
    setup(props, ctx) {
        const mailType = ref<EmailTypeInterface>({
            name: "",
            email_content: "",
        });

        const { createMailType, updateMailType } = useMailStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());

        const isAllFilled = computed(() => {
            let isFilled = false;
            if (mailType.value.name && mailType.value.email_content) {
                isFilled = true;
            }
            return isFilled;
        });

        watch(
            () => props.toEditData,
            () => {
                mailType.value = cloneData(props.toEditData);
            }
        );

        async function createEmailType(): Promise<void> {
            setLoadingId("save-button");
            if (props.toEditData && props.toEditData.id) {
                const response = await updateMailType(mailType.value);
                removeLoadingId("save-button");
                if (response?.status == 200) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message:
                                "E-Mail Typ wurde erfolgreich aktualisiert.",
                        },
                    ];
                    ctx.emit("close");
                }
            } else {
                const response = await createMailType(mailType.value);
                removeLoadingId("save-button");
                if (response?.status == 200 || response?.status == 201) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message: "E-Mail Typ wurde erfolgreich angelegt.",
                        },
                    ];
                    ctx.emit("close");
                }
            }
        }
        return { isAllFilled, mailType, createEmailType };
    },
});
