import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "trash",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1799",
      "data-name": "Rechteck 1799",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline3",
      d: "M830.1,107.618a1,1,0,0,1-1-1v-6a1,1,0,0,1,2,0v6A1,1,0,0,1,830.1,107.618Z",
      transform: "translate(-820.1 -90.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline2",
      d: "M834.1,107.713a1,1,0,0,1-1-1v-6a1,1,0,0,1,2,0v6A1,1,0,0,1,834.1,107.713Z",
      transform: "translate(-820.1 -90.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M840.1,95.618h-3v-1a1,1,0,0,0-1-1h-8a1,1,0,0,0-1,1v1h-3a1,1,0,0,0,0,2h1v12a2,2,0,0,0,2,2h10a2,2,0,0,0,2-2v-12h1a1,1,0,0,0,0-2Zm-3,14h-10v-12h10Z",
      transform: "translate(-820.1 -90.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}