
import { defineComponent } from "vue";

export default defineComponent({
    name: "IconPlus",
    props: {
        /**
         * Is the main stroke color of the icon. It should be a tailwind class without the theme prefix ('fill-').
         */
        color: {
            type: String,
            default: "grey-darker",
        },
    },
});
