
import { defineComponent, onMounted, reactive } from "vue";
import { storeToRefs } from "pinia";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputTextarea from "@/components/inputs/inputTextarea/InputTextarea.vue";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useMailStore } from "@/store/settings/mailStore";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "emailContent",
    components: { InputDefault, InputTextarea },
    setup() {
        const { emailContent } = storeToRefs(useMailStore());
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { fetchMailContent, updateEmailContent } = useMailStore();
        const state = reactive({});
        const router = useRouter();

        async function updateMailContent(): Promise<void> {
            const response = await updateEmailContent(emailContent.value);
            if (response?.status == 200) {
                toastMessageText.value = [
                    {
                        type: "success" as ToastMessageType,
                        message:
                            "E-Mail Inhalt wurde erfolgreich aktualisiert.",
                    },
                ];
            } else {
                toastMessageText.value = [
                    {
                        type: "error" as ToastMessageType,
                        message:
                            "E-Mail Inhalt konnte nicht aktualisert werden.",
                    },
                ];
            }
        }

        onMounted(async () => {
            const response = await fetchMailContent();
            if (response?.status != 200) {
                router.push({ name: "Settings" });
            }
        });
        fetchMailContent();
        return { state, emailContent, updateMailContent };
    },
});
