
import { computed, defineComponent, onMounted, ref } from "vue";
import InputSearch from "../../../../../components/inputs/inputSearch/InputSearch.vue";
import CustomButton from "../../../../../components/inputs/customButton/CustomButton.vue";
import IconPlus from "../../../../../components/icons/IconPlus.vue";
import TypeContainer from "../../../containers/EmailDataContainer.vue";
import AddTypeModal from "./modals/AddTypeModal.vue";
import DeleteModal from "../../../modals/ConfirmDeleteModal.vue";
import loadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import { EmailTypeInterface } from "@/store/settings/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useMailStore } from "@/store/settings/mailStore";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "emailChangeTypes",
    components: {
        InputSearch,
        CustomButton,
        IconPlus,
        TypeContainer,
        AddTypeModal,
        DeleteModal,
        loadingAnimation,
    },
    setup() {
        const { emailTypes } = storeToRefs(useMailStore());
        const { fetchMailTypes, deleteMailType } = useMailStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const openAddType = ref<boolean>(false);
        const openDeleteModal = ref<boolean>(false);
        const mailTypeFilter = ref<string>("");
        const toEditData = ref<EmailTypeInterface>({});
        const router = useRouter();

        const filteredMailTypes = computed(() => {
            return emailTypes.value.filter((mailType) =>
                mailType.name
                    ?.toLowerCase()
                    .includes(mailTypeFilter.value.toLowerCase())
            );
        });

        async function deleteData(): Promise<void> {
            setLoadingId("confirm-delete-button");
            await deleteMailType(toEditData.value);
            removeLoadingId("confirm-delete-button");
            toastMessageText.value = [
                {
                    type: "success" as ToastMessageType,
                    message: "E-Mail Typ wurde erfolgreich gelöscht.",
                },
            ];
            openDeleteModal.value = false;
            openAddType.value = false;
            toEditData.value = {};
        }

        const isLoading = ref(false);
        onMounted(async () => {
            emailTypes.value = [];
            isLoading.value = true;
            const response = await fetchMailTypes();
            isLoading.value = false;
            if (response?.status != 200) {
                router.push({ name: "Settings" });
            }
        });
        return {
            filteredMailTypes,
            openAddType,
            openDeleteModal,
            toEditData,
            mailTypeFilter,
            deleteData,
            emailTypes,
            isLoading,
        };
    },
});
